import { Controller } from "@hotwired/stimulus"
import Mark from "mark.js"
import { isMediumBreakpoint, isMobile } from "../../../ui/static_src/ui/utils/breakpoints"

const HIGHLIGHTED_CLASS = "-highlighted"
const HIDDEN_CLASS = "-hidden"
const MARK_CLASS = `mark[data-markjs="true"].${HIGHLIGHTED_CLASS}`

class TranscriptVideo extends Controller {
  static targets = ["chapter", "emptyText", "input", "paragraph", "speaker", "video"]
  static attributeStartTime = "data-transcript-video-start-timecode-value"
  static attributeEndTime = "data-transcript-video-end-timecode-value"
  static classActive = "-active"
  static classParagraph = ".transcript-paragraph__text"

  navigateToContent() {
    if (!this.videoTarget.paused) {
      const currentTime = this.videoTarget.currentTime
      // Get paragraph
      this.selectParagraph(currentTime)
      // Get chapter
      this.selectChapter(currentTime)
    }
  }

  selectParagraph(timecode) {
    const targetElement = this.getTargetElement(this.paragraphTargets, timecode)
    const currentElement = this.getCurrentElement(this.paragraphTargets, TranscriptVideo.classActive)

    if (targetElement) {
      this.format(currentElement, targetElement)
      // remove format
      if (currentElement) {
        const currentSpeaker = this.getSpeaker(currentElement)
        currentSpeaker.classList.remove(TranscriptVideo.classActive)
      }
      // apply format
      const speaker = this.getSpeaker(targetElement)
      speaker.classList.add(TranscriptVideo.classActive)
    }
  }

  selectChapter(timecode) {
    const targetElement = this.getTargetElement(this.chapterTargets, timecode)
    const currentElement = this.getCurrentElement(this.chapterTargets, TranscriptVideo.classActive)

    if (targetElement) {
      this.format(currentElement, targetElement)
    }
  }

  getTargetElement(targets, timecode) {
    return targets.find((element) => parseFloat(element.getAttribute(TranscriptVideo.attributeStartTime)) <= timecode && parseFloat(element.getAttribute(TranscriptVideo.attributeEndTime)) > timecode)
  }

  getCurrentElement(targets, cssClass) {
    return targets.find((element) => element.classList.contains(cssClass))
  }

  format(currentElement, targetElement) {
    if (currentElement && currentElement.getAttribute(TranscriptVideo.attributeStartTime) !== targetElement.getAttribute(TranscriptVideo.attributeStartTime)) {
      currentElement.classList.remove(TranscriptVideo.classActive)
    }
    targetElement.classList.add(TranscriptVideo.classActive)
  }

  getSpeaker(element) {
    let speaker
    let sibling = element.previousElementSibling
    while (sibling) {
      if (sibling.matches("span.transcript-paragraph__speaker")) {
        speaker = sibling
        return speaker
      }
      sibling = sibling.previousElementSibling
    }
  }

  play(e) {
    const timecode = e.target.value || e.currentTarget.getAttribute(TranscriptVideo.attributeStartTime)
    this.videoTarget.currentTime = parseFloat(timecode.replace(",", "."))
    this.videoTarget.play()

    if (!isMobile()) {
      this.scrollToElement(e.currentTarget)
    }
  }

  scrollToElement(e) {
    const timecode = parseFloat(e.getAttribute(TranscriptVideo.attributeStartTime))
    const element = e.getAttribute("data-transcript-video-target") === "chapter" ? this.getTargetElement(this.paragraphTargets, timecode) : this.getTargetElement(this.chapterTargets, timecode)
    element && element.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  highlight(e) {
    if (this.speakerTargets.length === 1) {
      return this.speakerTargets[0].classList.add(HIGHLIGHTED_CLASS)
    }

    // Remove previous highlight
    this.paragraphTargets.forEach((element) => element.classList.contains(HIGHLIGHTED_CLASS) && element.classList.remove(HIGHLIGHTED_CLASS))

    const speaker = this.getSpeaker(e.currentTarget)
    speaker && speaker.classList.add(HIGHLIGHTED_CLASS)
  }

  unhighlight() {
    this.speakerTargets.forEach((element) => element.classList.contains(HIGHLIGHTED_CLASS) && element.classList.remove(HIGHLIGHTED_CLASS))
  }

  search() {
    const query = this.inputTarget.value.trim()
    const marker = new Mark(TranscriptVideo.classParagraph)

    // Reset the display of all elements before applying the new search
    this.reset(this.paragraphTargets)
    this.reset(this.speakerTargets, true)
    this.emptyTextTarget.classList.add(HIDDEN_CLASS)

    // Unmark any previously marked elements
    marker.unmark({
      done: () => {
        if (query) {
          marker.mark(query, {
            separateWordSearch: false,
            className: HIGHLIGHTED_CLASS,
            done: (resultsCount) => {
              if (resultsCount === 0) {
                this.emptyTextTarget.classList.remove(HIDDEN_CLASS)
              }
              // Hide paragraphs without highlights
              this.paragraphTargets.forEach(paragraph => {
                if (!paragraph.querySelector(MARK_CLASS)) {
                  paragraph.style.display = "none"
                }
              })
              // Hide speakers not associated with highlighted paragraphs
              const filteredSpeakers = this.paragraphTargets
                .filter(paragraph => paragraph.querySelector(MARK_CLASS))
                .map(paragraph => this.getSpeaker(paragraph))
              this.speakerTargets.forEach(speaker => {
                if (!filteredSpeakers.includes(speaker)) {
                  speaker.style.display = "none"
                }
              })
            },
          })
        }
      },
    })
  }

  reset(targets, isInline = false) {
    targets.forEach(target => {
      target.style.display = isInline ? "inline-block" : isMediumBreakpoint() ? "grid" : "flex"
    })
  }

  connect() {
    this.navigateToContent()
  }
}

class Transcripts extends Controller {
  static targets = ["dataSentence", "emptyText", "input", "transcript"]
  static classTitle = "h1"

  search() {
    const query = this.inputTarget.value.trim()
    const marker = new Mark(Transcripts.classTitle)
    const readMoreButton = document.querySelector("#read-more__container")

    // Reset the display of all elements before applying the new search
    this.transcriptTargets.forEach(transcript => {
      transcript.style.display = "flex"
    })
    this.emptyTextTarget.classList.add(HIDDEN_CLASS)
    this.dataSentenceTarget.classList.remove(HIDDEN_CLASS)
    readMoreButton && readMoreButton.classList.remove(HIDDEN_CLASS)

    marker.unmark({
      done: () => {
        if (query) {
          marker.mark(query, {
            separateWordSearch: false,
            className: HIGHLIGHTED_CLASS,
            done: (resultsCount) => {
              if (resultsCount === 0) {
                this.emptyTextTarget.classList.remove(HIDDEN_CLASS)
                this.dataSentenceTarget.classList.add(HIDDEN_CLASS)
                readMoreButton && readMoreButton.classList.add(HIDDEN_CLASS)
              }
              // Hide transcripts without highlights
              this.transcriptTargets.forEach(transcript => {
                if (!transcript.querySelector(MARK_CLASS)) {
                  transcript.style.display = "none"
                }
              })
            },
          })
        }
      },
    })
  }
}

export { Transcripts, TranscriptVideo }
