import { Controller } from "@hotwired/stimulus"

class Alerts extends Controller {
  static targets = ["alertList", "iframe"]

  removeOldActive() {
    this.alertListTarget.querySelectorAll(".alerts__alert.-active").forEach(elt => {
      elt.classList.remove("-active")
    })
  }

  // TODO find a better solution to iPhone glitch
  _triggerSomeDummyOverlayForShittySafari() {
    const modal = document.createElement("dialog")
    modal.style.visibility = "hidden"
    document.body.appendChild(modal)
    modal.showModal()
    setTimeout(() => {
      modal.close()
    }, 100)
  }

  iframeTargetConnected(iframe) {
    this._triggerSomeDummyOverlayForShittySafari()
    iframe.addEventListener("load", iframeLoadEvent => {
      // TODO remove this when dedicated HTML views for Alerts will be implemented
      iframeLoadEvent.target.contentDocument.querySelectorAll("a").forEach(elt => {
        elt.setAttribute("target", "_blank")
      })
    })
  }

  iframeTargetDisconnected(iframe) {
    this._triggerSomeDummyOverlayForShittySafari()
  }
}

export { Alerts }
