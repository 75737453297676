import { Controller } from "@hotwired/stimulus"

class WatchGroup extends Controller {
  static targets = ["cancelButton", "editField", "header", "input", "label", "name", "newName", "saveButton", "watchgroup"]

  static classOpen = "-open"
  static classHidden = "-hidden"

  connect() {
    this.element.addEventListener("htmx:afterSwap", () => {
      this.toggleAlertState()
    })
  }

  watchgroupTargetConnected(element) {
    const inactiveWatchgroups = document.querySelectorAll(`.${WatchGroup.classOpen}[data-controller="${this.identifier}"]:not(.htmx-added)`)

    inactiveWatchgroups && inactiveWatchgroups.forEach(watchgroup => {
      const cancelButton = watchgroup.querySelector("[data-watchgroup-target=\"cancelButton\"]")
      cancelButton && cancelButton.click()
    })
  }

  toggleAlertState() {
    const keywords = this.element.querySelectorAll("ul li")

    if (keywords.length > 0) {
      const elements = this.element.querySelectorAll("[disabled]")
      elements && elements.forEach(element => {
        element.removeAttribute("disabled")
      })
    } else {
      const labels = this.element.querySelectorAll("[data-watchgroup-target=\"label\"]")
      labels.forEach(label => {
        label.firstChild.setAttribute("disabled", "")
      })
    }
  }

  edit() {
    this.headerTarget.classList.add(WatchGroup.classHidden)
    this.editFieldTarget.classList.remove(WatchGroup.classHidden)

    const input = this.inputTarget
    const end = input.value.length
    input.setSelectionRange(end, end)
    input.focus()
  }

  cancel() {
    this.hide()
  }

  done() {
    const newValue = this.inputTarget.value

    if (newValue !== this.nameTarget.innerHTML) {
      this.saveButtonTarget.disabled = false
    }

    this.nameTarget.innerHTML = newValue
    this.inputTarget.value = newValue
    this.newNameTarget.value = newValue
    this.hide()
  }

  hide() {
    this.editFieldTarget.classList.add(WatchGroup.classHidden)
    this.headerTarget.classList.remove(WatchGroup.classHidden)
  }
}

export { WatchGroup }
