import { Controller } from "@hotwired/stimulus"
import { normalize } from "../../../../ui/static_src/ui/utils/normalize"

import Mark from "mark.js"
import { useDebounce } from "stimulus-use"

class BillListSearch extends Controller {
  static debounces = ["search"]
  static targets = ["bills", "bill", "billSummary", "input"]
  static classSearching = "searching"
  static classMiss = "search-miss"
  static classResults = "search-results"

  search() {
    const query = normalize(this.inputTarget.value)
    const queryParts = query.split(" ")
    this.element.classList.toggle(BillListSearch.classSearching, !!query)
    const marker = new Mark(".bill:not(.search-miss) .bill__summary")
    const sections = document.querySelectorAll(".bills__list")
    marker.unmark()

    if (query) {
      this.billSummaryTargets.forEach((elt) => {
        const billName = normalize(elt.textContent)
        const hit = queryParts.reduce((memo, part) => {
          return memo && billName.includes(part)
        }, true)
        elt.closest(".bill").classList.toggle(BillListSearch.classMiss, !hit)
      })
      sections.forEach((elt) => {
        if (elt.querySelector(".bill:not(.search-miss)") !== null) {
          elt.classList.add(BillListSearch.classResults)
        } else {
          elt.classList.remove(BillListSearch.classResults)
        }
      })
      marker.mark(query, {
        className: "search-highlight",
        filter(_node, term) {
          return term.length > 1
        },
      })
    } else {
      this.billSummaryTargets.forEach((elt) => {
        elt.closest(".bill").classList.remove(BillListSearch.classMiss)
      })
      sections.forEach((elt) => {
        elt.classList.remove(BillListSearch.classResults)
      })
    }
  }

  clear() {
    this.inputTarget.value = ""
    this.search()
  }

  billsTargetConnected() {
    this.search()
    useDebounce(this, { wait: 200 })
  }
}

class BillListNav extends Controller {
  static targets = ["contents", "entry", "section"]
  static classHidden = "-hidden"
  static classActive = "-active"

  observerCallback = (intersectionEntries) => {
    intersectionEntries.forEach(intersectionEntry => {
      if (intersectionEntry.isIntersecting) {
        for (const entry of document.querySelectorAll(".sidenav__link")) {
          if (entry.getAttribute("id") === intersectionEntry.target.getAttribute("data-bill-list-nav-section-value")) {
            entry.classList.add(BillListNav.classActive)
          } else {
            entry.classList.remove(BillListNav.classActive)
          }
        }
      }
    })
  }

  observer = new IntersectionObserver(this.observerCallback, { rootMargin: "-30% 0px -70% 0px" })

  sectionTargetConnected(elt) {
    this.observer.observe(elt)
  }

  contentsTargetConnected() {
    this.entryTargets.forEach(elt => {
      if (document.querySelector(elt.getAttribute("href"))) {
        elt.classList.remove(BillListNav.classHidden)
      } else if (elt.getAttribute("data-bill-list-nav-optional-value") === "true") {
        elt.classList.add(BillListNav.classHidden)
      }
    })
  }
}

export { BillListNav, BillListSearch }
